import cn from 'classnames';
import React from 'react';
import Elements from 'components/Elements';
import OrderStatusEnum from 'const/OrderStatusEnum';
import DealEnum from 'model/DealEnum';
import { isArticleAndAccountNotesEqual } from 'services/api/utils';
import { currencyFormat, dateFormat } from 'services/utils';
import OrderActionsContext from 'storage/OrderActionsContext';
import PermissionEnum from '../../model/PermissionEnum';

type Props = {
  autoApproval: boolean;
  currency: string;
  dealType: DealEnum;
  onDeny: (order: WoaRequest) => void;
  onView: (order: WoaRequest) => void;
  order: WoaRequest;
  permissions: PermissionEnum[];
  showPrice: boolean;
};

function PendingOrdersViewRow({
  autoApproval,
  currency,
  dealType,
  onDeny,
  onView,
  order,
  permissions,
  showPrice,
}: Props) {
  const { approveOrder, isFetching: isFetchingMap } = OrderActionsContext.useContext();
  const isFetching = isFetchingMap[order.id];

  const onClickView = () => onView(order);
  const onClickApprove = () => approveOrder(order.id);
  const onClickDeny = () => onDeny(order);

  const { woaarticle: { woanotes = [] } = {} } = order;
  const isPending = OrderStatusEnum.PENDING === order.status;
  const isReadOnlyAdmin = permissions.includes(PermissionEnum.READ_ONLY_ADMIN);
  const isNoteIconVisible =
    woanotes.some(el => el.isPublic && !el.isInternal) || isArticleAndAccountNotesEqual(order.woaaccount, woanotes);

  return (
    <tr key={order.id} className="pendingOrdersView-dataRow">
      <td className="pendingOrdersView-dataCell">
        <div className="flex_v_center">
          <button
            className="btn btn-light btn-sm"
            data-seleniumid={`pendingOrdersView-viewOrder--${order.id}`}
            disabled={isFetching}
            onClick={onClickView}
            type="button"
          >
            View
          </button>
          {isNoteIconVisible && <span className="orderHasNoteIcon ml_5" title="There are notes" />}
        </div>
      </td>
      <td className="pendingOrdersView-dataCell nobr ta_c" data-seleniumid={`pendingOrdersView-createdTs--${order.id}`}>
        {dateFormat(order.createdTs)}
      </td>
      <td className="pendingOrdersView-dataCell" data-seleniumid={`pendingOrdersView-author--${order.id}`}>
        {order.author}
      </td>
      <td className="pendingOrdersView-dataCell" data-seleniumid={`pendingOrdersView-journalName--${order.id}`}>
        {order.journalName}
      </td>
      <td
        className="pendingOrdersView-dataCell nobr"
        data-seleniumid={`pendingOrdersView-allocatedAmount--${order.id}`}
      >
        {showPrice ? currencyFormat(order.allocatedAmount, currency) : 'N/A'}
      </td>
      {dealType === DealEnum.BOTH && (
        <td className="ordersView-dataCell ta_c" data-seleniumid={`pendingOrdersView-journalRevenueModel--${order.id}`}>
          {order.journalRevenueModel}
        </td>
      )}
      <td className={cn('pendingOrdersView-dataCell ta_r nobr', { 'is-loading': isFetching })}>
        {!isReadOnlyAdmin && isPending && !autoApproval && (
          <>
            <Elements.ConfirmButton
              classNameParent="nobr mr_10"
              disabled={isFetching}
              onClick={onClickApprove}
              seleniumid={`pendingOrdersView-approveOrder--${order.id}`}
              title="Do you want to approve?"
            >
              Approve
            </Elements.ConfirmButton>

            <button
              className="btn btn-secondary btn-sm"
              data-seleniumid={`pendingOrdersView-denyOrder--${order.id}`}
              disabled={isFetching}
              onClick={onClickDeny}
              title="Do you want deny?"
              type="button"
            >
              Deny
            </button>
          </>
        )}
      </td>
    </tr>
  );
}

export default PendingOrdersViewRow;
