/* eslint react/no-unused-state: 0 */

import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import OrderStatusEnum from 'const/OrderStatusEnum';
import { processing } from 'decorators';
import * as mainApi from 'services/api/main';
import { notify, withErrorNotification } from 'storage/NotificationsContext';
import mainWithContext from 'with/withContext';

type OrdersContextInterface = {
  ordersList: WoaRequest[];
  accountId?: number;
  isFetching: boolean;
  isReportSending: boolean;
  numOfResults?: number;
  keyword?: string;
  error?: Error;
  offset: number;
  totalOrders: number;
  getOrdersList: (accountId: number, params?: { keyword?: string; showAll?: boolean }, offset?: number) => void;
  reloadOrders: (showAll?: boolean) => void;
  requestOrdersReport: (dateFrom?: string, dateTo?: string) => void;
  updateOrdersList: () => void;
};

const defaultValue: OrdersContextInterface = {
  ordersList: [],
  isFetching: true,
  isReportSending: false,
  offset: 0,
  totalOrders: 0,
  getOrdersList: () => {},
  reloadOrders: () => {},
  requestOrdersReport: () => {},
  updateOrdersList: () => {},
};

const OrdersContext = React.createContext<OrdersContextInterface>(defaultValue);

function withProvider(WrappedComponent) {
  @autobind
  class OrdersProviderWrapper extends PureComponent<any, OrdersContextInterface> {
    state = { ...defaultValue, isFetching: true, getOrdersList: this.getOrdersList, numOfResults: 15, offset: 0 };

    @withErrorNotification
    @processing('isFetching')
    async getOrdersList(accountId: number, { keyword, showAll }: { keyword?: string; showAll?: boolean } = {}) {
      let { offset } = this.state;
      if (this.state.keyword !== keyword) {
        offset = 0;
      }

      const query = {
        statuses: [OrderStatusEnum.APPROVED].join(','),
      };

      this.setState({
        accountId,
        offset,
        keyword,
        numOfResults: 15,
        requestOrdersReport: (dateFrom, dateTo) =>
          this.requestOrdersReport(accountId, query.statuses, dateFrom, dateTo, keyword),
      });

      try {
        if (showAll) {
          query.statuses = [OrderStatusEnum.APPROVED, OrderStatusEnum.DENIED, OrderStatusEnum.CANCELLED].join(',');
          const ordersList = await mainApi.getOrders(accountId, keyword, query.statuses);

          this.setState({ error: null, ordersList });
        } else {
          const [ordersList, totalOrders] = await Promise.all([
            mainApi.getOrders(accountId, keyword, query.statuses, this.state.numOfResults, this.state.offset),
            mainApi.getRequestCount(accountId, query.statuses),
          ]);
          this.setState(state => ({
            error: null,
            ...state,
            ordersList: state.offset === 0 ? ordersList : state.ordersList.concat(ordersList),
            totalOrders: totalOrders > 0 ? totalOrders : 0, // Prevent totalOrders being -1,
          }));
        }
      } catch (error) {
        this.setState({ error, ordersList: [] });

        throw error;
      }
    }

    @withErrorNotification
    @processing('isReportSending')
    async requestOrdersReport(accountId, statuses, dateFrom, dateTo, keyword) {
      await mainApi.requestOrdersReport(accountId, statuses, dateFrom, dateTo, keyword);
      notify.info('Report is being generated. It will be sent by email upon completion.');
    }

    reloadOrders() {
      const { accountId, getOrdersList, keyword } = this.state;

      if (accountId) {
        getOrdersList(accountId, { keyword });
      }
    }

    updateOrdersList() {
      if (this.state.totalOrders > this.state.offset + this.state.numOfResults) {
        this.setState(
          state => {
            const newOffset = state.offset + state.numOfResults;
            return { ...state, offset: newOffset };
          },
          () => {
            this.reloadOrders();
          },
        );
      }
    }

    render() {
      return (
        <OrdersContext.Provider
          value={{
            ...this.state,
            reloadOrders: this.reloadOrders,
            updateOrdersList: this.updateOrdersList,
          }}
        >
          <WrappedComponent {...this.props} />
        </OrdersContext.Provider>
      );
    }
  }

  return OrdersProviderWrapper;
}

export { OrdersContextInterface };
export default {
  useContext: () => React.useContext(OrdersContext),
  withProvider,
  withContext: mainWithContext(OrdersContext),
};
