import React from 'react';
import StatusHelpLine from './StatusHelpLine';

export default function RequestStatuses() {
  return (
    <div style={{ border: '2px solid transparent', padding: '0 0.5em' }}>
      <div className="font-weight-bold text-center" style={{ marginTop: '1em' }}>
        Request Statuses
      </div>

      <StatusHelpLine status="Approved" tooltip="The request for funding for this article has been approved and will draw down from available balance after editorial acceptance, if balance is available." />

      <StatusHelpLine
        status="Denied"
        tooltip="Funding for this article has been denied. The author may be contacted for payment, in which case, the funding request will be switched to cancelled."
      />

      <StatusHelpLine
        status="Cancelled"
        tooltip="The funding request for this article has been cancelled. It will not be charged to the account balance. Funding request cancellation can occur when an Open Access article is not accepted for publication, when an article is rejected by the system due to lack of available balance or when an author updates their metadata such that an existing funding request is no longer valid."
      />
    </div>
  );
}
