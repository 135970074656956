import React, { useEffect } from 'react';
import ErrorBaner from 'components/ErrorBaner';
import LoaderLayout from 'components/Layouts/LoaderLayout';
import PendingOrdersList from 'components/PendingOrdersList';
import Search from 'components/Search/Search';
import SearchBanner from 'components/SearchBanner/SearchBanner';
import AccountContext from 'storage/AccountContext';
import { Emitter as NotesEmitter } from 'storage/ArticleNoteContext';
import { Emitter as OrdersEmitter } from 'storage/OrderActionsContext';
import PendingOrdersContext from 'storage/PendingOrdersContext';
import './PendingOrders.scss';

function PendingOrders() {
  const { selectedAccount } = AccountContext.useContext();
  const {
    error,
    isFetching,
    getPendingOrdersList,
    keyword,
    pendingOrders,
    reloadPendingOrders,
    updateOrdersList,
    numOfResults,
    totalOrders,
  } = PendingOrdersContext.useContext();

  useEffect(() => {
    const subscriptions = [
      NotesEmitter.NOTE_WAS_ADDED.subscribe(reloadPendingOrders),
      NotesEmitter.NOTE_WAS_UPDATED.subscribe(reloadPendingOrders),
      NotesEmitter.NOTE_WAS_REMOVED.subscribe(reloadPendingOrders),
      OrdersEmitter.ORDER_WAS_UPDATED.subscribe(reloadPendingOrders),
    ];

    return () => subscriptions.forEach(x => x());
  }, []);

  useEffect(() => {
    console.log("hay Cesar!");
    getPendingOrdersList(selectedAccount.id, { keyword }, 0);
  }, [selectedAccount.id]);

  const startSearch = value => {
    getPendingOrdersList(selectedAccount.id, { keyword: value }, 0);
  };

  const clearSearch = () => {
    getPendingOrdersList(selectedAccount.id, { keyword: undefined }, 0);
  };

  return (
    <LoaderLayout isLoading={isFetching}>
      <h4>{`Pending Requests (${pendingOrders.length})`}</h4>
      <Search
        buttonSeleniumId="pendingOrdersFilter-search"
        className="PendingOrders-Search"
        inputClassName="PendingOrders-SearchInput"
        inputSeleniumId="pendingOrdersFilter-searchInput"
        isLoading={isFetching}
        keyword={keyword}
        onSubmit={startSearch}
        placeholder="Author, article title, DOI, journal or manuscript ID"
      />
      <SearchBanner
        buttonSeleniumId="pendingOrdersFilter-clearSearchResult"
        isVisible={!!keyword}
        labelSeleniumId="pendingOrdersFilter-searchResult"
        onClear={clearSearch}
        text={`${isFetching ? '?' : pendingOrders.length} Results for your search for "${keyword}"`}
      />
      {pendingOrders.length === 0 && error == null && <div>You have no pending requests</div>}
      {pendingOrders.length > 0 && (
        <PendingOrdersList
          numOfResults={numOfResults}
          orders={pendingOrders}
          totalOrders={totalOrders}
          updateOrdersList={updateOrdersList}
        />
      )}
      <ErrorBaner error={error} />
      <hr />
    </LoaderLayout>
  );
}

export default React.memo(PendingOrders);
